import { Link } from '@remix-run/react';
import { Spacer } from '@/components/spacer';
import EqutumLogoHorizontal from '@/images/equtum_logo_h.svg?react';

// 404
export default function Page() {
  return (
    <div className="flex h-svh flex-col items-center justify-center bg-surface-base">
      <EqutumLogoHorizontal height={100} width={320} />
      <Spacer size={24} />
      <div className="text-center font-medium">このページは存在しません</div>
      <Spacer size={8} />
      <div className="text-center font-semibold text-active">
        <Link to="/">トップページに戻る</Link>
      </div>
    </div>
  );
}
